<template>
    <div class="login-container">
        <div class="login-box">
            <!-- <el-row> -->
                <!-- <el-col :lg="12"> -->
                    <div class="left">
                        <div class="title">演出数据看板</div>
                        <div class="desc">Performance data board</div>
                    </div>
                <!-- </el-col>
                <el-col :lg="12"> -->
                    <div class="right">
                        <div>
                            <div class="title">登录</div>
                            <div class="desc">请输入密码以查看数据详情</div>
                            <el-form :model="formData" :rules="rules" ref="formData" hide-required-asterisk>
                                <el-form-item prop="pw">
                                    <el-input v-model="formData.pw" type="password" placeholder="请输入密码" auto-complete="off"></el-input>
                                </el-form-item>
                                <el-button type="primary" class="btn" :loading="loginLoading"  @click="submitForm()">登录查看详情</el-button>
                            </el-form>
                        </div>
                    </div>
                <!-- </el-col> -->
            <!-- </el-row> -->
        </div>
    </div>
</template>

<script>
import cookie from 'js-cookie'
export default {
    data(){
        return{
            formData: {pw: "", type: 2},
            rules: {pw:[{required: true, message: "请输入密码" }]},
            loginLoading: false,
            redirect: this.$route.query.redirect || '/entryData',
        }
    },
    methods: {
        submitForm(){
            this.$refs["formData"].validate((valid) => {
                if (valid) {
                    this.loginLoading = true;
                    this.$request({
                        url: "/daping/login",
                        method: "POST",
                        params: {...this.formData}
                    }).then( res => {
                        const { state, msg, result } = res.data;
                        if (state != 1) {
                            return this.$message.error(msg || "请求失败");
                        } else {
                            cookie.set( 'entryDataSecretKey', result );
                            this.$router.push(this.redirect);
                        }
                    }).catch(() => {
                        this.$message.error('请求错误');
                    }).finally( () => {
                        this.loginLoading = false;
                    });
                } else {
                    return false;
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.login-container{
    background: #f8f8f8;
    height: 100vh;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    // padding: 20px;
    .login-box{
        display: flex;
        flex-wrap: wrap;
        .title{
            font-size: 24px;
            font-weight: 600;
        }
        .left{
            background: url(~@/assets/img/Ticketcheck/leftbg.png) center center no-repeat;
            background-size: cover;
            height: 360px;
            color: #ffffff;
            padding: 100px 50px 100px 50px;
            width: 100%;
            flex: 1;
            .desc{
                font-size: 12px;
                width: 80px;
                margin-top: 30px;
            }
        }
        .right{
            background: #ffffff;
            // width: 370px;
            height: 360px;
            max-width: 100%;
            padding: 0 50px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            .desc{
                font-size: 14px;
                color: #999;
                margin-top: 4px;
            }
            .el-form{
                margin-top: 40px;
                .el-input{
                    width: 270px;
                    ::v-deep .el-input__inner{
                        border-radius: 25px;
                        text-align: center;
                        background: #F5F6FA;
                        border-color: #F5F6FA;
                        height: 50px;
                        line-height: 50px;
                    }
                }
                .btn{
                    width: 100%;
                    border-radius: 25px;
                    height: 50px;
                    margin-top: 10px;
                    background: linear-gradient(218deg, #00FDEB 0%, #3969FF 100%);
                    border: 0;
                    ::v-deep * {
                        font-weight: 600;
                    }
                }
            }
        }
        @media screen and (max-width: 640px){
            .right{
                width: 100%;
            }
        }
    }
}
</style>